import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { ToastService } from './_core/_services/toast.service';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  showToast = false;
  toastMessage = '';

  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Load translations for the required languages from the backend
    this.translationService.loadTranslationsFromBackend('en');
    this.translationService.loadTranslationsFromBackend('pl');
    this.translationService.loadTranslationsFromBackend('de');

    this.modeService.init();

    this.toastService.toast$.subscribe((message) => {
      if (message) {
        this.toastMessage = message;
        this.showToast = true;
  
        // Trigger manual change detection
        this.cdr.detectChanges();
  
        // Hide the toast after a few seconds (optional if not using autohide)
        setTimeout(() => {
          this.showToast = false;
          this.cdr.detectChanges(); // Update UI
        }, 3000);
      }
    });
  }
}
